<template>
  <BrandsNewArrivalMB v-if="layout == 4" />
  <BrandsNewArrivalPC v-else />
</template>

<script>
import BrandsNewArrivalMB from "@/pages/mb/BrandsNewArrival";
import BrandsNewArrivalPC from "@/pages/pc/BrandsNewArrival";
import { ref, watch, provide, reactive } from "vue";
import BrandServices from "@/services/brand";
import { serialize } from "@/utlis/serialize";

export default {
  components: {
    BrandsNewArrivalMB,
    BrandsNewArrivalPC,
  },
  props: {
    hidePagination: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["totalBrands"],
  setup(props, { emit }) {
    const state = reactive({
      brandsNewArrival: [],
      loading: true,
      page: 1,
      totalPage: 0,
      hidePagination: props.hidePagination,
    });

    const pagination = ref({});
    const brandsBox = ref(null);

    async function onIntersectionBrandNew() {
      state.loading = true;

      const response = await BrandServices.fetchBrands(
        serialize({
          sort_by: "date",
          sort_order: "desc",
          get_banner: true,
          get_follow: true,
          status: "A",
          page: state.page,
          get_products: true,
          items_per_page: 30,
        })
      );

      if (response.data) {
        state.brandsNewArrival = Object.values(response.data.brands);
        pagination.value = response.data.params;
        state.totalPage = Math.ceil(
          pagination.value.total_items / pagination.value.items_per_page
        );
        state.page = pagination.value.page;
        state.loading = false;

        emit("totalBrands", pagination.value.total_items);
      }
    }

    async function onIntersectionBrandSugestion() {
      const response = await BrandServices.fetchBrands(
        serialize({
          sort_by: "random",
          sort_order: "desc",
          get_banner: true,
          get_follow: true,
          limit: 20,
          level_id: 20,
          status: "A",
        })
      );

      if (response.data) {
        state.brandsSugestion = Object.values(response.data.brands);
      }
    }

    watch(
      () => state.page,
      (page) => {
        window.scrollTo(0, brandsBox.value.offsetTop);
        onIntersectionBrandNew();
      }
    );

    provide("state", state);
    provide("brandsBox", brandsBox);
    provide("onIntersectionBrandNew", onIntersectionBrandNew);
    provide("onIntersectionBrandSugestion", onIntersectionBrandSugestion);
  },
};
</script>
