<template>
  <div class="dosiin_page-min-height dosiin_page-max-width dosiin_padding-header-cat-top">
    <div class="dosiin_wrapper-bar dosiin_mb-16">
      <Breadcrumbs title="Thương hiệu mới" :breadcumbs="breadcumbs" />
      <div class="dosiin-tab-bar-pc">
        <div class="tab-bar-pc-content">
          <div class="title-page d-flex align-items-center justify-content-between">
            <div class="title-all-categories">Thương hiệu mới</div>
            <div class="tab-bar-content-quantity">
              <span class="quantity" v-text="state.brandsNewArrival.length"> </span>
              <span class="text-quantity">Thương hiệu mới</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <section class="page-section">
      <Intersection @onIntersection="onIntersectionBrandNew()">
        <BrandVerticalSkeletonPC v-if="state.brandsNewArrival.length === 0" />
        <div v-else class="grid grid-33">
          <div
            class="grid-item brand-highlight_list"
            v-for="(brand, index) in state.brandsNewArrival"
            :key="index"
          >
            <div class="brand-highlight-item_wrapper brand-item">
              <div class="brand-highlight-item_img-wrapper brand-item_img-wrapper">
                <router-link :to="_brandUrl(brand.seo_name)" :title="brand.brand">
                  <img
                    class="brand-highlight-item_img"
                    v-lazy="_companyAvatar(brand.logos.theme)"
                    :alt="brand.brand"
                    :title="brand.brand"
                    width="76"
                    height="76"
                  />
                </router-link>
              </div>
              <div class="brand-highlight-item_info">
                <router-link :to="_brandUrl(brand.seo_name)" :title="brand.brand">
                  <h5 class="brand-item_title">{{ brand.company }}</h5>
                </router-link>
                <dl class="brand-item_social">
                  <dd class="brand-highlight-item_n-followers">
                    <b v-text="brand.follow_count"> </b>
                    <span> người theo dõi</span>
                  </dd>
                  <dd class="brand-highlight-item_n-likes">
                    <b>{{ brand.like_count }} </b>
                    <span> người thích trang</span>
                  </dd>
                </dl>
              </div>
              <div class="brand-highlight-item_follow-btn">
                <FollowButton
                  @update:modelValue="(newValue) => (brand.follow_count = newValue)"
                  class="brand_follow-btn dosiin_brand_follow-btn primary-button button follow-with-text-btn"
                  followable_type="brand"
                  :followable_id="brand.company_id"
                  :is_follow="brand.is_follow"
                />
              </div>
            </div>
          </div>
        </div>
      </Intersection>
    </section>

    <section class="page-section">
      <Intersection @onIntersection="onIntersectionBrandSugestion()">
        <BrandsSuggestHorizon
          title="Thương hiệu bạn có thể biết"
          gridClass="grid-20"
          :brands="state.brandsSugestion"
          :options="{
            slidesPerView: 4,
            slidesPerGroup: 4,
            spaceBetween: 9,
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            },
          }"
        />
      </Intersection>
    </section>
  </div>
</template>

<script>
import ProductNewestSkeletons from "@/components/skeletons/sections/ProductNewest";
import BrandsSuggestHorizon from "@/components/sliders/BrandsSuggestHorizon";
import BrandVerticalSkeletonPC from "@/components/skeletons/brand/BrandsNewArrivalPC.vue";
import { inject } from "@vue/runtime-core";

export default {
  components: {
    ProductNewestSkeletons,
    BrandsSuggestHorizon,
    BrandVerticalSkeletonPC,
  },
  setup() {
    const state = inject("state");
    const onIntersectionBrandNew = inject("onIntersectionBrandNew");
    const onIntersectionBrandSugestion = inject("onIntersectionBrandSugestion");

    return {
      state,
      onIntersectionBrandNew,
      onIntersectionBrandSugestion,
    };
  },
};
</script>

<style scoped>
.brand-item .brand-item_img-wrapper img {
  height: 100%;
}
</style>
