<template>
  <main class="home-new-brand">
    <BackHeader>
      <template v-slot:center>
        <h2 class="text-grey-lg title-header-category">Thương hiệu mới</h2>
      </template>
      <template v-slot:right>
        <div class="back-header-right-side">
          <router-link
            :to="{ name: 'search' }"
            class="search-icon dosiin_search-header-btn"
          >
            <i class="dsi dsi-search"></i>
          </router-link>
        </div>
      </template>
    </BackHeader>

    <hgroup class="product-page-header_wrap">
      <h1 class="title">Thương hiệu mới</h1>
      <h2 class="subtitle">Các thương hiệu mới của Việt Nam</h2>
    </hgroup>
    <div ref="brandsBox">
      <section
        class="brand-shop-page-section brand-shop-filter-tabs"
        id="dosiin_brand-shop-filter-tabs"
      >
        <div id="dosiin_tab-new-brand-new">
          <Intersection @onIntersection="onIntersectionBrandNew()">
            <BrandsNewArrivalMB v-if="state.brandsNewArrival.length === 0" />

            <template
              v-else
              v-for="(brand, index) in state.brandsNewArrival"
              :key="index"
            >
              <template v-if="Object.keys(brand.products).length > 0">
                <section class="dosiin_tab-new-brand-new">
                  <div class="tab-new-brand-item_brand">
                    <router-link :to="_brandUrl(brand.seo_name)" :title="brand.brand">
                      <div class="no-image" style="width: 100%">
                        <img
                          class="search-item_logo"
                          v-lazy="_companyAvatar(brand.logos.theme)"
                          :alt="brand.brand"
                          :title="brand.brand"
                          width="40"
                          height="40"
                        />
                      </div>
                    </router-link>

                    <dl class="new-brand_info">
                      <router-link :to="_brandUrl(brand.seo_name)" :title="brand.brand">
                        <dd class="search-item_title truncate">{{ brand.company }}</dd>
                      </router-link>
                    </dl>
                  </div>
                  <section class="banner-intro-section">
                    <router-link :to="_brandUrl(brand.seo_name)" :title="brand.brand">
                      <div class="banner">
                        <img
                          class="styled-img"
                          v-lazy="
                            _brandBanner(
                              brand.banner_shop[Object.keys(brand.banner_shop)[0]]
                            )
                          "
                          :alt="brand.brand"
                          :title="brand.brand"
                          width="375"
                          height="210"
                        />
                      </div>
                    </router-link>
                  </section>
                  <section class="page-section">
                    <div
                      class="list-item product-list-item"
                      v-for="(product, index) in brand.products"
                      :key="index"
                    >
                      <router-link :to="_productUrl(product)" :title="product.product">
                        <div class="no-image" style="width: 100%">
                          <img
                            class="cm-image ls-is-cached lazyloaded"
                            v-lazy="
                              _productThumbnailSticker(
                                product.main_pair,
                                product.sticker.main_pair
                              )
                            "
                            :alt="product.product"
                            width="68"
                            height="68"
                          />
                        </div>
                      </router-link>
                      <dl class="list-item_summary">
                        <dt>
                          <router-link
                            class="list-item_brand"
                            :to="_brandUrl(brand.seo_name)"
                            :title="brand.brand"
                            v-text="product.company_name"
                          ></router-link>
                        </dt>
                        <dd class="list-item_name">
                          <router-link
                            :to="_productUrl(product)"
                            :title="product.product"
                            v-text="product.product"
                          >
                          </router-link>
                        </dd>
                        <div class="list-item_sale product-list-item_price_wrapper">
                          <dd class="list-item_price">
                            {{ numberFormat(product.price) }}đ
                          </dd>
                          <template v-if="product.discount > 0">
                            <dd class="inline-bl list-item_sale_origin">
                              {{ numberFormat(product.list_price) }}₫
                            </dd>
                            <dd class="inline-bl list-item_sale_discount">
                              -{{ Math.round(product.discount) }}%
                            </dd>
                          </template>
                        </div>
                      </dl>
                    </div>

                    <div class="seemore-btn">
                      <router-link
                        class="primary-button button"
                        :to="_brandUrl(brand.seo_name)"
                        title="Khám phá thương hiệu"
                      >
                        <span class="primary-link_text">Khám phá thương hiệu</span>
                        <svg
                          width="18"
                          height="13"
                          viewBox="0 0 18 13"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            class="see-more-right-arrow-custom"
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M17.3333 6.39359L12.082 0.853147C11.9693 0.731823 11.819 0.666668 11.6577 0.666668C11.5052 0.666668 11.3615 0.725083 11.2532 0.832926C11.1383 0.943016 11.0742 1.09355 11.072 1.25531C11.0698 1.41483 11.1272 1.56536 11.2355 1.67994L12.5616 3.07741L14.5971 5.22529L15.1386 5.79596H14.3585H12.3229H1.26117C1.04457 5.79596 0.85008 5.90829 0.748413 6.09028C0.640116 6.27676 0.640116 6.50817 0.748413 6.69465C0.85229 6.87888 1.04678 6.99122 1.25454 6.99122H12.3229H14.3585H15.1386L14.5971 7.56189L12.5616 9.70976L11.2377 11.1072C11.0676 11.2847 11.0189 11.5409 11.114 11.7655C11.209 11.9835 11.4212 12.125 11.6532 12.125H11.6555H11.6643C11.8212 12.1205 11.9737 12.0509 12.0798 11.9363L17.3333 6.39359Z"
                            fill="url(#see-more-right-arrow)"
                          ></path>
                          <defs>
                            <linearGradient
                              id="see-more-right-arrow"
                              x1="0.667191"
                              y1="0.666668"
                              x2="11.3658"
                              y2="16.2278"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stop-color="#ED55C7"></stop>
                              <stop offset="1" stop-color="#6756CA"></stop>
                            </linearGradient>
                          </defs>
                        </svg>
                      </router-link>
                    </div>
                  </section>
                </section>
              </template>
            </template>

            <section class="page-section" v-if="state.brandsNewArrival.length != 0">
              <div class="pagination-bottom" id="pagination-bottom">
                <Pagination
                  v-if="state.totalPage > 1 && !state.hidePagination"
                  v-model="state.page"
                  :pages="state.totalPage"
                />
              </div>
            </section>
          </Intersection>
        </div>
      </section>
    </div>
  </main>
</template>

<script>
import BackHeader from "@/components/common/BackHeader";
import BrandsNewArrivalMB from "@/components/skeletons/brand/BrandsNewArrivalMB";
import Pagination from "@/components/pagination/Pagination";
import { inject } from "@vue/runtime-core";

export default {
  components: {
    BackHeader,
    Pagination,
    BrandsNewArrivalMB,
  },
  setup() {
    const state = inject("state");
    const brandsBox = inject("brandsBox");
    const onIntersectionBrandNew = inject("onIntersectionBrandNew");

    return {
      state,
      brandsBox,
      onIntersectionBrandNew,
      Pagination,
    };
  },
};
</script>
